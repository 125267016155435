

















































import { Component, Vue, Prop } from 'vue-property-decorator'

import Modal from '@/components/modals/Default.vue'
import AttachUsers from './partials/AttachUsers.vue'
import UserImage from '@/components/user/UserImage.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import Pagination from '@/components/paginations/Pagination.vue'

import { ImplementationResource } from '@/models/implementations/ImplementationResource'
import { UserResource } from '@/models/users/UserResource'
import { AuthUserResource } from '@/models/users/AuthUserResource'
import { userModule } from '@/store'

@Component({
  components: {
    Modal,
    UserImage,
    Pagination,
    SmallLoader,
    AttachUsers
  }
})
export default class ImplementationDetailTeam extends Vue {

  @Prop()
  private implementation!: ImplementationResource

  private index: IndexResponse<UserResource> | null = null
  private isFetchingData: boolean = false

  private isDeletingUser: number | null = null

  private isAttachUsersModalOpen: boolean = false

  private get hasData(): boolean {
    return this.index ? this.index.data.length > 0 : false
  }

  private get canAddUsers(): boolean {
    return this.implementation.canPerformAction('can_add_users_to_implementation')
  }

  private get canDeleteUser(): boolean {
    return this.implementation.canPerformAction('can_delete_lead')
  }

  private created(): void {
    this.getData()
  }

  // Computed
  private get currentUser(): AuthUserResource | undefined {
    return userModule.user
  }

  private canDeleteTheUser(user: UserResource): boolean {
    return this.canDeleteUser && user.id !== this.currentUser?.id
  }

  private async getData(): Promise<void> {
    try {
      this.isFetchingData = true
      this.index = await this.implementation.getUsers()
    } finally {
      this.isFetchingData = false
    }
  }

  private async deleteUser(user: UserResource): Promise<void> {
    try {
      this.isDeletingUser = user.id
      this.index = await this.implementation.deleteUser(user)
    } finally {
      this.isDeletingUser = null
    }
  }

  private usersAttached(): void {
    this.isAttachUsersModalOpen = false
    this.getData()
  }

  private openAttachUsersModal(): void {
    this.isAttachUsersModalOpen = true
  }

  private closeAttachUsersModal(): void {
    this.isAttachUsersModalOpen = false
  }
}
